import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Layout from '../components/Layout/Layout';

export default () => {

  const { page }= useStaticQuery(graphql`
  {
    page: markdownRemark(fields: {slug: {eq: "datenschutzerklaerung"}}){
      html
    }
  }
  `)

  return (
    <Layout>
      <div className="legal" dangerouslySetInnerHTML={{__html: page.html}}/>
    </Layout>
  )
}